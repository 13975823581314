/* eslint-disable */
import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "../../../Components";

function SessionExpired() {
  useLayoutEffect(() => {
    localStorage.removeItem("med_user");
  }, []);

  const goToLogin = () => {
    window.location.href = "/";
  }
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }


  useEffect(() => {
    const handleResize = debounce(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <section id="sessionExpired">

      <div className={`${size.width > 750 ? "card" : ""} shadow`}>
        <div className="card-body">
          <img
            width="150"
            height="150"
            src="https://img.icons8.com/nolan/150/expired.png"
            alt="expired"
          />
          <span className="cardTitle">Session Expired</span>
          <span className="description-session">
            Your session has come to an end. Kindly log in again.
          </span>
          <Button
            minWidth="7rem"
            height="2.5rem"
            className="d-flex align-items-center justify-content-center text-white px-0 rounded cursor-pointer"
            onClick={() => goToLogin()}
          >
            Login
          </Button>
        </div>
      </div>

    </section>
  );
}

export default SessionExpired;
