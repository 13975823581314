/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "react-dots-loader";

import { Box, Button, CheckBox, Typography } from "../../Components";

import { ROUTER_PATH } from "../../config";

import { useEffect } from "react";
import {
  GetPatentAssessmentId,
  getChatByUsers,
  getDoctorProfile,
} from "../../Redux/actions";
import { getLoggedInUser } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import useWindowSizeHook from "../../Components/WindowSizeHook";

const COLUMN_BOX_PROPS = {
  background: "#ffffff",
  borderRadius: "0.75rem",
  width: "100%",
  height: "100%",
  maxWidth: "202rem",
};
const VALIIDATION_SCHEMA = Yup.object().shape({
  option1: Yup.bool().required("Please check the above consent!"),
  option2: Yup.bool().required("Please check the above consent"),
  option3: Yup.bool().required("Please check the above consent"),
});

export default function Home() {
  const { width } = useWindowSizeHook();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const medUser = getLoggedInUser();
  const chat = useSelector((state) => state.chat);
  const patient = useSelector((state) => state.patientProfile);

  useEffect(() => {
    if (medUser) {
      dispatch(getDoctorProfile({ isSystemDoctor: true }));
      dispatch(GetPatentAssessmentId(medUser?.userId));
    }
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("pageReloaded")) {
      window.location.reload();
      sessionStorage.setItem("pageReloaded", "true");
    }
  }, []);

  const apiData = patient?.GET_PATIENT_ASSESSMENT_ID_DATA?.data;

  const PATIENT_DETAILS = [
    {
      title: "Name",
      desc: apiData?.name || "N/A",
    },
    {
      title: "Age",
      desc: apiData
        ? `${apiData?.age} ${apiData?.isMonth === false ? "Years" : "Months"}`
        : "N/A",
    },
    {
      title: "Occupation",
      desc: apiData?.occupation || "N/A",
    },
    {
      title: "Weight",
      desc: apiData
        ? `${apiData?.weightMeasurement} ${apiData?.isKg === false ? "(lbs)" : "(kg) "
        }`
        : "N/A",
    },
    {
      title: "height",
      desc: apiData
        ? `${apiData?.heightMeasurement} ${apiData?.isKg === false ? "(ft/in)" : "(cm)"
        }`
        : "N/A",
    },
    {
      title: "Mobile Contact",
      desc: apiData?.mobileContact || "N/A",
    },

    {
      title: "Location",
      desc: apiData?.address || "N/A",
    },
  ];

  const token = JSON.parse(localStorage.getItem("med_user"))?.token;

  console.log("token", token);

  useEffect(() => {
    if (medUser?.userId && chat?.DOCTOR_PROFILE?.data[0]?.doctorId) {
      dispatch(
        getChatByUsers({
          pageNo: 1,
          size: 20,
          search: null,
          isPagination: true,
          sortBy: null,
          sortDirection: null,
          fromUserId: medUser?.userId,
          toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
          isSessionEnd: false,
          isTitleMessage: false,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
      );
    }
  }, [chat?.DOCTOR_PROFILE?.data, medUser?.userId]);

  const formik = useFormik({
    initialValues: {
      option1: "",
      option2: "",
      option3: "",
    },
    validationSchema: VALIIDATION_SCHEMA,
    onSubmit: (values) => {
      if (!values.option1) {
        // toast.error("Please Check Data Consent to continue!");
        return;
      }
      if (!values.option2) {
        // toast.error("Please Check Communication Consent to continue!");
        return;
      }
      if (!values.option3) {
        // toast.error("Please Check Diagnostic Consent to continue!");
        return;
      }
      if (chat?.CHAT?.length > 0)
        navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
          state: { gender: chat?.CHAT[0]?.roleId === 101 ? "male" : "female", language: chat?.CHAT[0]?.language },
        });
      else navigate(ROUTER_PATH.START_CHAT);
    },
  });

  console.log("profileAssessmentDataById", apiData);

  // const SIGN_IN_SCHEMA = Yup.object().shape({
  //   email: Yup.string().required("Please enter your email address"),
  //   password: Yup.string().required("Please enter your password"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //   },
  //   validationSchema: SIGN_IN_SCHEMA,
  //   onSubmit: (values) => {
  //     if (
  //       values.email === "patient@medistreamline.com" &&
  //       values.password === "patient@123"
  //     ) {
  //       localStorage.setItem("med_user", JSON.stringify(values));
  //       navigate(ROUTER_PATH.HOME_PAGE);
  //     } else {
  //       Toaster({ message: "Email or Password is wrong!", type: "error" });
  //     }
  //   },

  // });

  return (
    <Box
      width="100%"
      maxWidth="202rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className={` ${width > 600 ? "m-auto p-5" : " p-3 m-auto"}    `}
    >
      <Typography
        as={width < 1300 ? "h5" : "h2"}
        align="center"
        color="rgb(147, 112, 219)"
      >
        Welcome to Medistreamline
      </Typography>
      <Typography
        as="p"
        align="center"
        color="var(--logo-black-700, #4A4F4D)"
        className="pb-5 px-2"
      >
        Your AI doctor! Get personalized medical advice and diagnoses from the
        comfort of your screen
      </Typography>

      {/* <Row className="py-3">
        <Col lg={6} className="my-3">
          <Box {...COLUMN_BOX_PROPS} className="px-4 pt-3 pb-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <Typography as={width < 1300 ? "h6" : "h4"} color="#2E3130">
                Patient Details
              </Typography>

              <Button
                minWidth="7rem"
                height="2.5rem"
                className={`bg-light-purple d-flex align-items-center justify-content-center  px-0 rounded cursor-pointer  `}
                onClick={
                  () => navigate(ROUTER_PATH.HEALTH_PROFILE)
                  // navigate(
                  //   ROUTER_PATH.UPDATE_HEALTH_PROFILE.replace(
                  //     ":id",
                  //     medUser?.userId
                  //   )
                  // )
                }
              >
                view Profile
              </Button>
            </div>
            <Box
              width="100%"
              maxWidth="85rem"
              height="3px"
              background="#E5E6E6"
              border="1px solid #E5E6E6"
            ></Box>

            {patient?.GET_PATIENT_ASSESSMENT_ID_LOADING ? (
              <div className="h-screen d-flex justify-content-center align-items-center">
                <Loader size={15} />
              </div>
            ) : (
              <Row className=" ">
                {PATIENT_DETAILS.map((item) => (
                  <Col xxl={4} xl={6} lg={6} sm={6}>
                    <Typography as="label" color="#636967" className="mt-4">
                      {item.title}
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      {item.desc}
                    </Typography>
                  </Col>
                ))}
              </Row>
            )}
          </Box>
        </Col>

        <Col lg={6} className="my-3">
          <Box {...COLUMN_BOX_PROPS} className="px-4 pt-3 pb-4">
            <Typography
              as={width < 1300 ? "h6" : "h4"}
              color="#2E3130"
              className="mb-2"
            >
              Appointment Details
            </Typography>
            <Box
              width="100%"
              maxWidth="85rem"
              height="3px"
              background="#E5E6E6"
              border="1px solid #E5E6E6"
            ></Box>
            <Row>
              <Col
                xxl={6}
                xl={12}
                lg={12}
                className="d-flex align-items-center py-4"
              >
                <div>
                  <img src={IMAGES.FOLLOW_UP_ICON} alt="" width="50px" />
                </div>
                <div className="ms-3">
                  <Typography as="label" color="#636967">
                    Appt. Type
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    Follow-Up
                  </Typography>
                </div>
              </Col>
              <Col
                xxl={6}
                xl={12}
                lg={12}
                className="d-flex align-items-center py-2"
              >
                <div>
                  <img src={IMAGES.DATE_ICON} alt="" width="50px" />
                </div>
                <div className="ms-3">
                  <Typography as="label" color="#636967">
                    Appt. Date
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    June 11, 2023 on Wednesday
                  </Typography>
                </div>
              </Col>
              <Col
                xxl={6}
                xl={12}
                lg={12}
                className="d-flex align-items-center py-2"
              >
                <div>
                  <img src={IMAGES.TIME_ICON} alt="" width="50px" />
                </div>
                <div className="ms-3">
                  <Typography as="label" color="#636967">
                    Appt. Time
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    10:00 AM
                  </Typography>
                </div>
              </Col>
            </Row>
          </Box>
          <Row className="mt-4">
            <Col xs={6}>
              <Button
                // backgroundColor="#fff"
                type="button"
                className="w-100"
                onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
                minWidth="auto"
              >
                <Typography
                  align="center"
                  fontWeight="500"
                  fontSize="1rem"
                  color="#fff"
                >
                  Chat History
                </Typography>
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                // backgroundColor="#fff"
                type="button"
                className="w-100"
                minWidth="auto"
                onClick={() => navigate(ROUTER_PATH.SUBSCRIPTION)}
              >
                <Typography
                  align="center"
                  fontWeight="500"
                  fontSize="1rem"
                  color="#fff"
                >
                  Subscription
                </Typography>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Box {...COLUMN_BOX_PROPS} className="p-5">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Typography
            color="#2E3130"
            fontSize={width < 500 ? "1.3rem" : "1.7rem "}
            fontWeight="500"
          >
            Patient Details
          </Typography>

          <Button
            minWidth="7rem"
            height={"   2.5rem"}
            className={` d-flex align-items-center justify-content-center text-white px-0 rounded cursor-pointer  `}
            onClick={() => navigate(ROUTER_PATH.HEALTH_PROFILE)}
          >
            View Profile
          </Button>
        </div>
        {patient?.GET_PATIENT_ASSESSMENT_ID_LOADING ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader size={10} />
          </div>
        ) : (
          <Row>
            {PATIENT_DETAILS.map((item) =>
              item.title !== "Location" ? (
                <Col xxl={4} xl={6} lg={6} sm={6}>
                  <Typography as="label" color="#636967" className="mt-4">
                    {item.title}
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    {item.desc}
                  </Typography>
                </Col>
              ) : (
                <Col lg={12} xl={6} xxl={6}>
                  <Typography as="label" color="#636967" className="mt-4">
                    {item.title}
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    {item.desc}
                  </Typography>
                </Col>
              )
            )}
          </Row>
        )}
      </Box>
      <Row className="py-3">
        <Col xs={6}>
          <Button
            // backgroundColor="#fff"
            type="button"
            className="w-100"
            onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
            minWidth="auto"
          >
            <Typography
              align="center"
              fontWeight="500"
              fontSize="1rem"
              color="#fff"
            >
              Chat History
            </Typography>
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            // backgroundColor="#fff"
            type="button"
            className="w-100"
            minWidth="auto"
            onClick={() => navigate(ROUTER_PATH.SUBSCRIPTION)}
          >
            <Typography
              align="center"
              fontWeight="500"
              fontSize="1rem"
              color="#fff"
            >
              Subscription
            </Typography>
          </Button>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        <Box {...COLUMN_BOX_PROPS} className="p-4">
          <Typography as={width < 1300 ? "h6" : "h4"}>
            Please check the consents below
          </Typography>

          <div className="my-2">
            <div className="d-flex align-items-start mb-0">
              <CheckBox
                // className="py-1"
                name="option1"
                id="checkbox1"
                checked={formik.values.option1}
                onChange={(e) => {
                  formik.setFieldValue(
                    "option1",
                    e.target.checked === true ? true : ""
                  );
                }}
              // onChange={formik.handleChange}
              // label="Data Consent:
              // By using our services, you consent to the collection, storage, and processing of your personal data in accordance with our privacy policy for the purpose of providing you with our AI-driven healthcare services."
              />
              <label for="checkbox1" className="cursor-pointer">
                <b>Data Consent: </b> By using our services, you consent to the
                collection, storage, and processing of your personal data in
                accordance with our privacy policy for the purpose of providing
                you with our AI-driven healthcare services.
              </label>
            </div>
            {formik.touched.option1 && formik.errors.option1 && (
              <small className="text-danger small mb-2">
                {formik.errors.option1}
              </small>
            )}
          </div>
          <div className="mb-2">
            <div className="d-flex align-items-start mb-0">
              <CheckBox
                // className="py-1"
                name="option2"
                id="checkbox2"
                checked={formik.values.option2}
                onChange={(e) => {
                  formik.setFieldValue(
                    "option2",
                    e.target.checked === true ? true : ""
                  );
                }}
              //   label="Communication Consent:
              // You consent to receiving communications, including updates, notifications, and healthcare information, from Medistreamline via email or other preferred communication channels."
              />
              <label for="checkbox2" className="cursor-pointer">
                <b>Communication Consent: </b>You consent to receiving
                communications, including updates, notifications, and healthcare
                information, from Medistreamline via email or other preferred
                communication channels.
              </label>
            </div>
            {formik.touched.option2 && formik.errors.option2 && (
              <small className="text-danger small mb-2">
                {formik.errors.option2}
              </small>
            )}
          </div>
          <div className="mb-2">
            <div className="d-flex align-items-start mb-0">
              <CheckBox
                // className="py-1"
                name="option3"
                id="checkbox3"
                checked={formik.values.option3}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.setFieldValue(
                    "option3",
                    e.target.checked === true ? true : ""
                  );
                }}
              //   label="Diagnostic Consent:
              // You understand that Medistreamline is an AI-based tool and not a substitute for professional medical advice. You consent to using our service with the understanding that the diagnoses and advice provided are based on AI algorithms and should be verified with a healthcare professional for accuracy and suitability for your specific situation."
              />
              <label for="checkbox3" className="cursor-pointer">
                <b>Diagnostic Consent: </b> You understand that Medistreamline
                is an AI-based tool and not a substitute for professional
                medical advice. You consent to using our service with the
                understanding that the diagnoses and advice provided are based
                on AI algorithms and should be verified with a healthcare
                professional for accuracy and suitability for your specific
                situation.
              </label>
            </div>
            {formik.touched.option3 && formik.errors.option3 && (
              <small className="text-danger small mb-2">
                {formik.errors.option3}
              </small>
            )}
          </div>
        </Box>

        <div
          className={
            width < 768
              ? "d-flex justify-content-center mt-3 align-items-center"
              : "d-flex justify-content-end  mt-3"
          }
        >
          <Button
            type="submit"
          // onClick={() => navigate(ROUTER_PATH.START_CHAT)}
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="700"
              color="#fff"
            >
              Continue
            </Typography>
          </Button>
        </div>
      </Form>
    </Box>
  );
}
